import styled from 'styled-components';

import { theme } from '../../../styles';
import { Button } from '../../../components/button/button';

export const StyledProgressBar = styled.span`
  position: absolute;
  height: 22px;
  background-color: ${theme.colors.grey[100]};
  width: ${({ progress }) => progress}%;
  z-index: 0;
`;

export const StyledCancelButton = styled(Button)`
  z-index: 1;
`;


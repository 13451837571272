import { Map } from 'immutable';

import { UPDATE_CACHE } from '../../ibis-ui/actions/index';

const defaultState = Map({
});

export const cacheReducer = (state = defaultState, action) => {
  switch (action.type) {

  case UPDATE_CACHE:
    return state.setIn([ action.payload.name ], action.payload.value);

  default:
    return state;
  }
};

import _ from 'lodash';

export const setGV = (key, value) => {
  _.set(window, [ 'reactApp', key ], value);
};

export const removeGV = (key) => {
  _.set(window, [ 'reactApp', key ], undefined);
};

export const getGV = (key, withRemove = false) => {
  const value = _.get(window, [ 'reactApp', key ], undefined);
  if (withRemove) removeGV(key);
  return value;
};

export const clearGV = () => {
  _.set(window, 'reactApp', undefined);
};

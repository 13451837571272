import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'reactstrap';
import { useLocation } from 'react-router';

import Loading from '../../../../main/components/loading';
import { NodesGrid } from './nodesGrid/nodesGrid';
import { NodesList } from './nodesList/nodesList';
import { useIBIS } from '../../../hooks/useIbis';
import { Button } from '../../../components/button/button';
import { ReactComponent as More } from '../../../../assets/svg/icons/more.svg';
import { ReactComponent as Tile } from '../../../../assets/svg/icons/tile.svg';
import { ReactComponent as List } from '../../../../assets/svg/icons/list.svg';
import { NodeSelectorContainer } from './nodeSelector/nodeSelectorContainer';
import {
  StyledDropdownButton,
  StyledDropdownIcon,
} from '../../../components/dropdown/dropdown.styled';
import Dropdown from '../../../components/dropdown/dropdown';

const views = {
  GRID: "grid",
  LIST: "list",
  SELECTOR: "selector",
};
const Directory = () => {
  const [ view, setView ] = useState(views.LIST);
  const { t } = useTranslation();
  const IBIS = useIBIS();

  const node = useRef();
  const [ dropdown, setDropdown ] = useState(false);

  const { pathname } = useLocation();
  const currentNodeReadMe = useIBIS(false, pathname + "README.md");

  if (
    (!IBIS.node && IBIS.isLoading) ||
    (IBIS.node?.items?.find(
      (node) => node.name.toLowerCase() === "readme.md"
    ) &&
      currentNodeReadMe.isLoading)
  ) {
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <div>
          <Row className="justify-content-center">
            <Loading />
          </Row>
          <h6>{t("ibis-ui:loading")}</h6>
        </div>
      </Row>
    );
  }

  if (IBIS.error)
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <h6>
          {IBIS.error.response?.status === 403
            ? t("ibis-ui:forbidden")
            : IBIS.error.response?.status === 404
              ? t("ibis-ui:directory_not_found")
              : t("ibis-ui:backend_error")}
        </h6>
      </Row>
    );

  if (!IBIS.node)
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <h6>{t("ibis-ui:directory_not_found")}</h6>
      </Row>
    );

  if (IBIS?.node && (!IBIS.node.items || IBIS.node.items?.length === 0))
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <h6>{t("ibis-ui:no_resources")}</h6>
      </Row>
    );

  return (
    <>
      <Row className="m-2">
        <div ref={node} className="position-relative">
          <Button
            type="secondary"
            icon={<More />}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setDropdown(!dropdown);
            }}
          />
          {dropdown && (
            <Dropdown
              triggerRef={node}
              changeDropdownState={setDropdown}
              className="mt-2 p-2"
            >
              <StyledDropdownButton
                onClick={() => {setView(views.LIST); setDropdown(false);}}
                className="p-1"
              >
                <StyledDropdownIcon className="pr-2">
                  <List className="align-self-center" />
                </StyledDropdownIcon>
                {t("ibis-ui:list")}
              </StyledDropdownButton>
              <StyledDropdownButton
                onClick={() => {setView(views.GRID); setDropdown(false);}}
                className="p-1"
              >
                <StyledDropdownIcon className="pr-2">
                  <Tile className="align-self-center" />
                </StyledDropdownIcon>
                {t("ibis-ui:grid")}
              </StyledDropdownButton>
              {IBIS.node?.ui?.views[0]?.view?.selector && (
                <StyledDropdownButton
                  onClick={() => {setView(views.SELECTOR); setDropdown(false);}}
                  className="p-1"
                >
                  <StyledDropdownIcon className="pr-2">
                    <Tile className="align-self-center" />
                  </StyledDropdownIcon>
                  {t("ibis-ui:selector")}
                </StyledDropdownButton>
              )}
            </Dropdown>
          )}
        </div>
      </Row>
      {view === views.GRID ? (
        <NodesGrid resources={IBIS.node.items} loadMore={IBIS.fetchMore} />
      ) : view === views.SELECTOR ? (
        <NodeSelectorContainer nestedLevels={IBIS.node?.ui?.views[0]?.view?.selector?.depth} />
      ) : (
        <NodesList resources={IBIS.node.items} loadMore={IBIS.fetchMore} />
      )}
      {IBIS.isLoading && (
        <Row className="m-0 mt-4 justify-content-center align-items-center">
          <div>
            <Row className="justify-content-center">
              <Loading />
            </Row>
            <h6>{t("ibis-ui:loading")}</h6>
          </div>
        </Row>
      )}
    </>
  );
};

export default Directory;

import * as ibis from './client';
import { __env } from '../../envloader';
import axiosInstance from '@axios';
import { getGV } from '../../main/utils/globalVariablesProvider';

function getSecret() {
  return getGV("secret");
}

let client = new ibis.Client({
  tenantUrl: __env.IBIS_TENANT_URL,
  axiosInstance: axiosInstance,
  getSecret: getSecret,
});

export function setClient(newClient) {
  client = newClient;
}

export function fetchDirectory({ path, cursor, fetchAll, pageSize }) {
  const query = cursor ? cursor : pageSize ? { page_size: pageSize } : null;
  return client.fetchDirectory({
    path: path,
    query: query,
    fetchAll: fetchAll,
  });
}

export function fetchFile({
  path,
  responseType,
  detectContentType,
  contentType,
}) {
  return client.fetchFile({
    path,
    responseType,
    detectContentType,
    contentType,
  });
}

export function createNode({
  path,
  node,
  onUploadProgress,
  contentType,
  method,
  signal,
}) {
  return client.createNode({
    node: node,
    path,
    onUploadProgress,
    contentType,
    method,
    signal,
  });
}

export function createDirectory({ path, attributes }) {
  return createNode({ path, node: { attributes } });
}

export function createFile({ path, file, onUploadProgress, signal }) {
  let formData = new FormData();
  formData.append("content", file);

  return createNode({
    path,
    node: formData,
    onUploadProgress,
    method: "post",
    contentType: "multipart/form-data",
    signal,
  });
}

export function patchFile({ path, file, onUploadProgress, signal }) {
  let formData = new FormData();
  formData.append("content", file);

  return createNode({
    path,
    node: formData,
    onUploadProgress,
    method: "put",
    contentType: "multipart/form-data",
    signal,
  });
}

export function _delete({ path }) {
  return client.deleteNode({ path });
}

export function patchIBISfile({ path, content }) {
  const directory = client.bindDirectory(
    path.substring(0, path.lastIndexOf("/") + 1)
  );
  const name = path.substring(path.lastIndexOf("/") + 1);
  const file = directory.bind({
    attributes: {},
    content: content,
    content_type: "application/json",
    name: name,
    size: "",
    user_content_last_modified: "",
    type: "file",
  });

  return file.patch().then((response) => {
    return response;
  });
}

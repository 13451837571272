import styled from 'styled-components';

export const StyledText = styled.pre`
  font-size: 0.875rem;
  font-family: monospace;
`;

export const StyledJson = styled.pre`
  font-size: 0.875rem;
  font-family: monospace;
`;

export const StyledImage = styled.img`
  max-width: 100%;
`;
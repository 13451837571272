import { CLEAR_STORE } from '.';
import actions from '../actions';
import * as functions from '../../ibis-ui/services';

export const fetchDirectory =
  ({ path, cursor = null, fetchAll = false, pageSize, replace = false }) =>
    async (dispatch) => {
      dispatch(actions.setLoading({ path: path, replace: replace }));
      return functions
        .fetchDirectory({
          path,
          cursor,
          fetchAll,
          pageSize,
        })
        .then((response) => {
          dispatch(
            actions.setNode({
              node: response.data,
              path: path,
              replace: replace || fetchAll,
            })
          );
          return response;
        })
        .catch((e) => {
          dispatch(actions.setError({ error: e, path: path }));
        });
    };

export const fetchFile =
  ({ path, detectContentType }) =>
    async (dispatch) => {
      dispatch(actions.setLoading({ path: path }));
      try {
        const fileResponse = await functions.fetchFile({
          path,
          detectContentType,
        });
        dispatch(actions.setNode({ path: path, node: fileResponse.data }));
        return fileResponse;
      }
      catch (e) {
        dispatch(actions.setError({ error: e, path: path }));
      }
    };

export const _delete =
  ({ path }) =>
    async () => {
      return functions._delete({
        path: path,
      });
    };

export const createFile = ({ path, file, onUploadProgress = null, signal }) => {
  return functions.createFile({
    path,
    file,
    onUploadProgress,
    signal,
  });
};

export const patchFile = ({ path, file, onUploadProgress = null, signal }) => {
  return functions.patchFile({
    path,
    file,
    onUploadProgress,
    signal,
  });
};

export const createDirectory = ({ path, attributes }) => {
  return functions.createDirectory({
    path,
    attributes,
  });
};

export const createNode = ({ path, node }) => {
  return functions.createNode({
    path,
    node,
  });
};

export const clearNode = ({ path }) =>
  async (dispatch) => {
    return dispatch(actions.clearNode({ path }));
  };

export function clearStore() {
  return {
    type: CLEAR_STORE,
    payload: {},
  };
}

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { List } from 'immutable';
import {
  Col,
  Container,
  Row,
  ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components/button/button';
import { Input } from '../../components/input/input';
import {
  StyledAddAttribute,
  StyledModalBody,
  StyledDeleteButton,
  StyledModal,
} from './nodeCreator.styled';
import useIBIS from '../../hooks/useIbis';
import { ReactComponent as Add } from '../../../assets/svg/icons/add.svg';
import { ReactComponent as Trash } from '../../../assets/svg/icons/trash.svg';

const DirectoryCreator = ({ modal, closeModal }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const IBIS = useIBIS();

  const splitedPathname = pathname.split('/');
  const [ name, setName ] = useState(splitedPathname[splitedPathname.length-1] 
    ? splitedPathname[splitedPathname.length-1] : splitedPathname[splitedPathname.length-2]);
  const pathnameForFolderFromURLCreation = pathname.slice(0, pathname.indexOf(name)) || "";
  const [ attributes, setAttributes ] = useState(List([]));

  const close = () => {
    setName("");
    setAttributes(List([]));
    closeModal();
  };

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleAttributeChange = (event, index) => {
    setAttributes(
      attributes.set(index, {
        ...attributes.get(index),
        [event.target.name]: event.target.value,
      })
    );
  };

  const remove = (index) => {
    setAttributes(attributes.delete(index));
  };

  const add = () => {
    setAttributes(attributes.push({ key: "", value: "" }));
  };

  const submit = () => {
    if (name.length > 0) {
      let parsedAttributes = {};
      attributes.toJS().forEach((x) => {
        if (x.key.length > 0 && x.value.length > 0)
          parsedAttributes[x.key] = x.value;
      });
  
      IBIS.createDirectory({
        path: (!IBIS.node ? pathnameForFolderFromURLCreation + name : pathname + name) + "/",
        attributes: parsedAttributes,
      }).finally(() => {
        close();
      });
    }
  };

  const createAttributesFields = () => {
    return (
      <>
        {attributes.map((_, index) => (
          <Row key={index} className={`d-flex ${index > 0 ? "pt-2" : ""}`}>
            <Col className="p-0 pr-2">
              <Input
                name="key"
                placeholder="key"
                value={attributes.get(index).key}
                onChange={(e) => handleAttributeChange(e, index)}
              />
            </Col>
            <Col className="p-0 pr-2">
              <Input
                name="value"
                placeholder="value"
                value={attributes.get(index).value}
                onChange={(e) => handleAttributeChange(e, index)}
              />
            </Col>
            <StyledDeleteButton
              icon={<Trash />}
              type="secondary"
              name="-"
              onClick={() => remove(index)}
            />
          </Row>
        ))}
      </>
    );
  };

  return (
    <StyledModal isOpen={modal} toggle={close}>
      <div className='p-3'>
        <h6 className="m-0">{t("ibis-ui:create_folder")}</h6>
      </div>
      <StyledModalBody className="p-3">
        <Container>
          <Row>
            <label htmlFor="name">{t("ibis-ui:folder_name")} *</label>
            <Input disabled={!IBIS.node} name="name" value={name} onChange={handleChange} />
          </Row>

          <Row className="py-3">
            <label className="m-0" htmlFor="attributes">
              {t("ibis-ui:attributes")}
            </label>
            <StyledAddAttribute
              name="attributes"
              className="ml-3"
              icon={<Add />}
              type="secondary"
              onClick={add}
            ></StyledAddAttribute>
          </Row>

          {createAttributesFields()}
        </Container>
      </StyledModalBody>
      <ModalFooter className="p-3">
        <Row className="m-0 float-right">
          <Button type="secondary" onClick={close} className="mr-4">
            {t("ibis-ui:cancel")}
          </Button>
          <Button disabled={name.length === 0} onClick={submit}>{t("ibis-ui:add_folder")}</Button>
        </Row>
      </ModalFooter>
    </StyledModal>
  );
};

DirectoryCreator.propTypes = {
  modal: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default DirectoryCreator;

import React from 'react';
import axios from '@axios';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';

import { Button } from './button';
import { __env } from '../../../envloader';
import { ReactComponent as DownloadIcon } from '../../../assets/svg/icons/download.svg';
import { fetchFile } from '../../services';

const Download = ({ path, size }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const downloadLargeFile = async () => {
    const response = await axios.head(
      `${__env.IBIS_TENANT_URL}${path.substr(1)}?token-for-download=`
    );
    const token = response.headers["ibis-token-query"];
    const link = document.createElement("a");

    link.href = `${__env.IBIS_TENANT_URL}${path.substr(1)}?${token}`;
    link.click();
  };

  const downloadFile = () => {
    fetchFile({ path: path, responseType: "blob" }).then((file) => {
      FileSaver.saveAs(file.data.content, pathname.split("/").slice(-1)[0]);
    });
  };

  return (
    <Button
      icon={<DownloadIcon />}
      className="ml-2"
      onClick={() =>
        size > __env.LARGE_FILE_SIZE ? downloadLargeFile() : downloadFile()
      }
    >
      {t("ibis-ui:download")}
    </Button>
  );
};

export default Download;

Download.propTypes = {
  path: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
};

import styled from 'styled-components';

import { theme } from '../../styles';

export const sidebarWidth = 72;

export const StyledSidebar = styled.div`
  position: sticky;
  top: 0;
  min-width: ${sidebarWidth}px;
  max-width: ${sidebarWidth}px;
  max-height: 100vh;

  background-color: ${theme.colors.grey["0"]};
  display:flex;
  flex-direction:column;
  align-items:center;
  padding-top:1rem;

  .navbar-brand {
    width:100%;
    max-height:60px;
    margin:0 auto 0 auto;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
  }

  .navbar-brand > svg {
    width:100%;
    transform: scale(0.75);
  }

  button {
    width: 40px;
    height: 40px;
  }
`;

export const StyledSidebarFlexContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${theme.colors.grey["100"]};
  margin-top: 16px;

  button {
    margin-top: 16px;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';

import Loading from '../../../../../../main/components/loading';
import { StyledImage } from './imageIcon.styled';
import useIBIS from '../../../../../hooks/useIbis';

export const ImageIcon = ({ name }) => {
  const IBIS = useIBIS(true, name);

  if (IBIS.isLoading) {
    return (
      <Row className="m-0 h-100 justify-content-center align-items-center">
        <Loading />
      </Row>
    );
  }

  if (IBIS.error) return null;

  if (!IBIS.node) return null;
  return (
    <StyledImage
      src={URL.createObjectURL(IBIS.node.content)}
      alt={"None"}
    />
  );
};

ImageIcon.propTypes = {
  name: PropTypes.string,
};

export default ImageIcon;

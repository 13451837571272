import React from 'react';

import { StyledInput } from './input.styled';

export const Input = ({
  ...rest
}) => {

  return (
    <StyledInput {...rest}/>
  );
};

import React, { Component } from 'react';
import { Nav, Collapse } from 'reactstrap';
import { Route, Link } from 'react-router-dom';
import { NavbarBrand } from 'reactstrap';

import { ReactComponent as IBIS } from '../../../assets/svg/icons/logo.svg';
import Login from '@main/containers/login';
import { ReactComponent as Signet } from '../../../assets/svg/icons/signet.svg';
import { StyledNavbar, StyledQuestionIcon } from './topbar.styled';

export default class TopBar extends Component {

  render() {
    return (
      <StyledNavbar expand>
        <NavbarBrand className='h-100' tag={Link} to={'/' + window.location.search}><Signet className='mr-2 h-100 w-auto'/><IBIS/></NavbarBrand>
        <Collapse navbar>
          <Nav className='ml-auto d-flex h-100' navbar pills >
            <StyledQuestionIcon className='align-self-center mr-3'/>
            <Route strict path='/' render={props => (
              <Login location={props.location} />
            )} />
          </Nav>
        </Collapse>
      </StyledNavbar>
    );
  }
}


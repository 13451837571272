import * as nodesActions from './nodes';
import * as JSONPatchActions from './JSONPatch';

export const namespace = '@IBIS/';

export const CLEAR_STORE = namespace + 'CLEAR_STORE';

const actions = {
  ...nodesActions,
  ...JSONPatchActions
};

export default actions;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from '../../../../styles';

export const StyledNodesGrid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  width: 100%;
`;

export const StyledFileName = styled.h5`
  font-weight: 700;
  color: ${theme.colors.grey["600"]};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledNodesTileItem = styled(Link)`
  all: unset;
  :hover {
    text-decoration: none;
    cursor: pointer;
  }
  :focus {
    background: ${theme.colors.grey["0"]};
  }
  display: flex;
  flex-direction: column;
  height: 260px;
  border-style: solid;
  border-width: 1px;
  border-color: ${theme.colors.grey["50"]};
  box-shadow: 0px 4px 6px ${theme.colors.grey["100"]};
  border-radius: 6px;
`;

export const StyledIcon = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  svg {
    padding: 1rem;
    width: auto;
    height: 100%;
  }
`;

export const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  border-top-style: solid;
  border-width: 2px;
  border-color: ${theme.colors.grey["50"]};
  height: 100px;
`;

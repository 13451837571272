import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import NodeContainer from '../nodeContainer/nodeContainer';
import { StyledNodesList, StyledHeader, StyledTableRow, StyledTableHead } from './nodesList.styled';
import { NodesListItem } from './nodesListItem';
import { useInfiniteScroll } from '../../../../hooks/useInfiniteScroll';

export const NodesList = ({ resources }) => {
  const { t } = useTranslation();
  const { lastElementRef } = useInfiniteScroll();

  const names = resources.map((node) => {
    return node.name;
  });
  const [ checkedFields, setFields ] = useState([]);
  const [ fieldsState, setFieldState ] = useState(
    names.reduce(
      (name, value) => ({
        ...name,
        [value]: false,
      }),
      {}
    )
  );

  const handleCheckbox = (e) => {
    let mutableList = [ ...checkedFields ];
    if (e.target.checked) {
      mutableList = [ ...checkedFields, e.target.name ];
      setFieldState({ ...fieldsState, [`${e.target.name}`]: true });
    }
    else {
      mutableList.splice(checkedFields.indexOf(e.target.name), 1);
      setFieldState({ ...fieldsState, [`${e.target.name}`]: false });
    }
    setFields(mutableList);
  };

  const checkAll = (e) => {
    if (e.target.checked) {
      setFields(names);
    }
    else {
      setFields([]);
    }
    setFieldState(
      names.reduce(
        (name, value) => ({
          ...name,
          [value]: e.target.checked,
        }),
        {}
      )
    );
  };

  const renderList = resources.map((node, index) => {
    return (
      <NodeContainer
        key={node.name + node.type}
        type={node.type}
        name={node.name}
      >
        <NodesListItem
          innerRef={index === resources.length - 1 ? lastElementRef : null}
          {...node}
        >
          <Input
            disabled
            type="checkbox"
            checked={fieldsState[`${node.name}`]}
            name={node.name}
            onClick={(e) => {
              e.stopPropagation();
              handleCheckbox(e);
            }}
          />
        </NodesListItem>
      </NodeContainer>
    );
  });

  return (
    <>
      <StyledNodesList>
        <StyledTableHead>
          <StyledTableRow>
            <StyledHeader size="sm">
              <Input disabled type="checkbox" onClick={checkAll} />
            </StyledHeader>
            <StyledHeader size="lg">{t("ibis-ui:name")}</StyledHeader>
            <StyledHeader size="md">{t("ibis-ui:content_type")}</StyledHeader>
            <StyledHeader size="md">{t("ibis-ui:size")}</StyledHeader>
            <StyledHeader size="lg">{t("ibis-ui:last_modified")}</StyledHeader>
            <StyledHeader size="md">{t("ibis-ui:attributes")}</StyledHeader>
            <StyledHeader></StyledHeader>
          </StyledTableRow>
        </StyledTableHead>
        {renderList}
      </StyledNodesList>
    </>
  );
};

NodesList.propTypes = {
  resources: PropTypes.array,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router';

import Directory from './nodes/directory/directory';
import File from './nodes/file/file';
import { getDirectoryPath } from '../utils/urlHelpers.js';
import { useIBIS } from '../hooks/useIbis';


const MainDisplay = ({ hashPathname }) => {
  const { pathname } = useLocation();
  useIBIS(hashPathname ? hashPathname === pathname : true, undefined, hashPathname ? true : false);

  return (
    <>
      <div className='pb-3'>
        <Switch>
          {
            !(pathname.lastIndexOf('/') === (pathname.length - 1)) &&
                  <Route exact
                    path={'/' + getDirectoryPath(pathname) + pathname.slice(pathname.lastIndexOf('/') + 1)}
                    render={() => <File />} />
          }
          <Route path={'/'} component={Directory} />
        </Switch>
      </div>
    </>
  );
};

MainDisplay.propTypes = {
  hashPathname: PropTypes.string,
};

export default MainDisplay;

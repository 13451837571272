import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ReduxToastr from 'react-redux-toastr';

import Root from './containers/root';
import configureStore from './stores/configureStore';
import { injectStore } from './utils/axios/axiosInstance';
import ScrollToTop from './components/scrollToTop';
import { GlobalStyle } from '../ibis-ui/styles/index';

const { history, store } = configureStore();

injectStore(store);

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollToTop />
      <div data-testid='app-test'>
        <GlobalStyle />
        <Suspense fallback={<div></div>}>
          <Root />
          <ReduxToastr
            timeOut={0}
            newestOnTop={false}
            preventDuplicates
            removeOnHover={false}
            removeOnHoverTimeOut={10000}
            position="bottom-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            //progressBar
            closeOnToastrClick={false}
          />
        </Suspense>
      </div>
    </ConnectedRouter>
  </Provider>
);

export default App;

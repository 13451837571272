import { toastr } from 'react-redux-toastr';

import { ReactComponent as ErrorIcon } from '../../assets/svg/icons/toastr-error.svg';
import { ReactComponent as WarningIcon } from '../../assets/svg/icons/toastr-warning.svg';
import { ReactComponent as SuccessIcon } from '../../assets/svg/icons/toastr-success.svg';

const toastrErrorOptions = {
  showCloseButton: false,
  closeOnToastrClick: true,
  removeOnHover: false,
  icon: ( <ErrorIcon /> )
};

export const error = (title, message) => {
  if (!message) toastrErrorOptions.className  = 'without-text';
  toastr.error(title, message, toastrErrorOptions);
};

const toastrSuccessOptions = {
  timeOut: 5000,
  showCloseButton: false,
  closeOnToastrClick: true,
  removeOnHover: true,
  icon: ( <SuccessIcon /> )
};

export const success = (title, message) => {
  if (!message) toastrErrorOptions.className  = 'without-text';
  toastr.success(title, message, toastrSuccessOptions);
};

const toastrWarningOptions = {
  showCloseButton: false,
  closeOnToastrClick: true,
  removeOnHover: false,
  icon: ( <WarningIcon /> )
};

export const warning = (title, message) => {
  if (!message) toastrErrorOptions.className  = 'without-text';
  toastr.warning(title, message, toastrWarningOptions);
};

const toastrInfoOptions = {
  removeOnHover: false
};

export const info = (title, message) => {
  toastr.info(title, message, toastrInfoOptions);
};

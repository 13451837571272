import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { theme } from '../../../../styles';

export const StyledNodeSelectorContainer = styled.div`
  display: flex;
`;

export const StyledNodeSelectorList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1rem;
  border-right: 1px solid ${theme.colors.grey["100"]};
`;

export const StyledNodeSelectorDisplay = styled.div`
  width: 100%;
`;

export const StyledIndentation = styled.div`
  padding-left: 1.5rem;
  font-weight: bold;
`;

export const StyledLink = styled(Link)`
  all: unset;
  color: ${({ loading }) =>
    loading ? theme.colors.primary[100] : theme.colors.primary[500]};
  cursor: pointer;
  :hover {
    all: unset;
    color: ${({ loading }) =>
    loading ? theme.colors.primary[100] : theme.colors.primary[500]};
    cursor: pointer;
  }
`;


export const StyledLinkButton = styled.button`
  path {
    stroke: ${({ loading }) =>
    loading ? theme.colors.primary[100] : theme.colors.primary[500]};
  }
  all: unset;
  font-weight: bold;
  color: ${({ loading }) =>
    loading ? theme.colors.primary[100] : theme.colors.primary[500]};
  cursor: pointer;
`;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import url from 'url-browser';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';

import { logIn, logInFinish } from '../../oidc-middleware/actions/login';
import * as notify from '../utils/notify';
import { ErrorBoundary } from '../hoc/errorboundary';
import { __env } from '../../envloader';
import { getSS } from '../utils/sessionStorageProvider';

@ErrorBoundary((props) => {
  return `Callback, ${props.t('common:incompatible_state')}`;
})
@withTranslation()
@connect(
  null,
  dispatch => {
    return bindActionCreators({
      logInFinish: logInFinish,
      logIn: logIn,
    }, dispatch);
  }
)
export default class Callback extends Component {
  componentDidMount() {
    const { t } = this.props;
    const querystring = this.props.location.search;
    const query = url.parse("/" + querystring, true).query;
    if (query && query.error === "USER_UNAUTHORISED_FOR_THIS_APPLICATION") {
      notify.error(t('common:authorization_error_notification_title'), t('common:authorization_error_notification_body'));
    }
    else if (query.error !== 'login_required')
      this.props.logInFinish({ data: query });
    else if (query.error === 'login_required' && getSS('after-token-refresh-error', true)) {
      if (__env.AUTH_REFRESH_FAIL_REACTION === 'notify')
        notify.error(t('common:session_end_notification_title'), t('common:session_end_notification_body'));
      if (__env.AUTH_REFRESH_FAIL_REACTION === 'reauthenticate')
        this.props.logIn();
    }
  }

  render() {
    const pathname = getSS('redirect_url_pathname', true);
    const search = getSS('redirect_url_search', true);
    
    if (pathname)
      return (
        <Redirect to={{
          pathname: pathname,
          search: search
        }} />
      );
    else
      return (
        <Redirect to="" />
      );
  }
}

Callback.propTypes = {
  location: PropTypes.object,
  logInFinish: PropTypes.func, //HOC
  logIn: PropTypes.func, //HOC
  t: PropTypes.func //HOC
};

import { forcedLogOut } from './actions/login';

const interval = 2000;

export class SessionCheck {
  constructor(client_id , session_state, url, store) {
    this.store = store;

    //create an invisible iframe
    this.iframe = window.document.createElement("iframe");
    this.iframe.style.visibility = "hidden";
    this.iframe.style.display = "none";
    this.iframe.style.width = 0;
    this.iframe.style.height = 0;

    //compose a message
    this.clientId = client_id;
    this.sessionState = session_state;
    this.messageContent = this.clientId + " " + this.sessionState;

    //append the iframe to body and add event listiner to read the response
    window.document.body.appendChild(this.iframe);
    this.boundMessageEvent = this.message.bind(this);
    window.addEventListener("message", this.boundMessageEvent, false);

    //define the message function
    let send = () => {
      this.iframe.contentWindow.postMessage(this.messageContent, "*");
    };

    //set the oidp endpoint that iframe connects to
    this.iframe.src = url;

    //set the timer for requests
    this.timer = window.setInterval(send, interval);
  }

  message(e) {
    //if session status is changed than update page
    if (e.data === "changed") {
      this.store.dispatch(forcedLogOut());
      window.clearInterval(this.timer);
    }
  }
}
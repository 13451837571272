import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useIBIS } from '../../hooks/useIbis';
import { Button } from './button';

import { ReactComponent as EditIcon } from '../../../assets/svg/icons/edit.svg';

const Edit = ({ setFileModal, setEditFileModal }) => {
  const IBIS = useIBIS();
  const { t } = useTranslation();

  const openModal = (e) => {
    e.stopPropagation();
    setEditFileModal(true);
    setFileModal(true);
  };

  return IBIS.node ?
    <Button type='secondary' className='ml-2' onClick={openModal} svgProperty={"fill"}
      icon={<EditIcon />} >
      {t('ibis-ui:edit')}
    </Button>
    : null;
};

Edit.propTypes = {
  setFileModal: PropTypes.func,
  setEditFileModal: PropTypes.func,
};

export default Edit;

import { Map, fromJS } from 'immutable';

import { SET_NODE, SET_LOADING, SET_ERROR, APPLY_PATCH, CLEAR_NODE } from '../../ibis-ui/actions/index';

import { CLEAR_STORE } from '../interface-actions/index';

const defaultState = Map({});

export const nodeReducer = (state = defaultState, action) => {
  let newState;
  switch (action.type) {
  case SET_NODE:
    newState = state.setIn(
      [ action.payload.path, "node" ],
      fromJS(action.payload.node)
    ).setIn(
      [ action.payload.path, "isLoading" ],
      false
    ).setIn(
      [ action.payload.path, "error" ],
      null
    );

    if (action.payload.replace) {
      return newState;
    }

    if (
      newState.getIn([ action.payload.path, "node", "items" ]) &&
        state.getIn([ action.payload.path, "node", "items" ])
    ) {
      newState = newState.setIn(
        [ action.payload.path, "node", "items" ],
        state
          .getIn([ action.payload.path, "node", "items" ])
          .concat(newState.getIn([ action.payload.path, "node", "items" ]))
      );
    }
    return newState;

  case SET_LOADING:
    newState = state.setIn(
      [ action.payload.path, "isLoading" ],
      true
    ).setIn(
      [ action.payload.path, "error" ],
      null
    );

    if (action.payload.replace)
      return newState.setIn(
        [ action.payload.path, "node" ],
        null
      );

    return newState;

  case SET_ERROR:
    return state.setIn(
      [ action.payload.path, "error" ],
      action.payload.error
    ).setIn(
      [ action.payload.path, "isLoading" ],
      false
    );

  case CLEAR_NODE:
    return state.delete( action.payload.path );

  case CLEAR_STORE:
    return state.clear();

  case APPLY_PATCH:
    try {
      const immpatch = require("immpatch");
      return state.set(
        action.payload.path,
        immpatch(state.get(action.payload.path), action.payload.patch)
      );
    }
    catch (e) {
      throw e;
    }

  default:
    return state;
  }
};

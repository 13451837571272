import { __env } from '../../envloader/index';

export const setSS = (key, value) => {
  sessionStorage.setItem(__env.BROWSER_STORAGES_PREFIX_APP_NAME + '_' + key, value);
};

export const getSS = (key, withRemove = false) => {
  const lsItem = sessionStorage.getItem(__env.BROWSER_STORAGES_PREFIX_APP_NAME + '_' + key);
  if (withRemove) {
    sessionStorage.removeItem(__env.BROWSER_STORAGES_PREFIX_APP_NAME + '_' + key);
  }
  return lsItem;
};

export const removeSS = (key) => {
  sessionStorage.removeItem(__env.BROWSER_STORAGES_PREFIX_APP_NAME + '_' + key);
};

export const clearSS = () => {
  sessionStorage.clear();
};

export const clearOwnSS = () => {
  let keyTab = [];
  for (let i = 0; i < sessionStorage.length; ++i) {
    let key = sessionStorage.key(i);
    if (key.includes(__env.BROWSER_STORAGES_PREFIX_APP_NAME)) {
      keyTab.push(key);
    }
  }
  keyTab.forEach(key => { sessionStorage.removeItem(key); });
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Container, Col } from 'reactstrap';
import styled from 'styled-components';


import MainDisplay from './pages/mainDisplay';
import Sidebar from './pages/sidebar/sidebar';
import { topbarHeight } from './pages/topbar/topbar.styled';
import { sidebarWidth } from './pages/sidebar/sidebar.styled';
import Header from './pages/nodes/header/header';

const StyledMainContainer = styled.div`
  display:flex;
  min-height: calc(100vh - ${topbarHeight}px);
`;

const StyledContentContainer = styled(Col)`
  padding: 0;
  max-width: calc(100vw - ${sidebarWidth}px);
  display:flex;
  flex-direction: column;
`;

@withTranslation()
@connect(
  state => ({
    // Kind of fake - used only to refresh content after login and logout || !! Now is used for check logined user on header. !!
    loginStore: state.login.get('loginData'),
  })
)
export default class Main extends Component {
  render() {
    return (
      <Container fluid className='p-0'>
        <StyledMainContainer className='m-0'>
          <Sidebar />
          <StyledContentContainer>
            <Header loginStore={this.props.loginStore} />
            <MainDisplay toplevel={true}/>
          </StyledContentContainer>
        </StyledMainContainer>
      </Container>
    );
  }
}

Main.propTypes = {
  t: PropTypes.func, //HOC
  logIn: PropTypes.func,
  loginStore: PropTypes.object,
  entitiesStore: PropTypes.object,
};

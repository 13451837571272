import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';
import PropTypes from 'prop-types';

import FileCreator from '../../nodeCreator/fileCreator';
import DirectoryCreator from '../../nodeCreator/directoryCreator';
import Download from '../../../components/button/download';
import Delete from '../../../components/button/delete';
import Refresh from '../../../components/button/refresh';
import { removeMeta } from '../../../utils/urlHelpers.js';
import BreadCrumbs from './breadcrumbs/breadCrumbs';
import AddNode from './addNode/addNode';
import Attributes from '../directory/attributes/attributes';
import ReadMe from './readMe/readMe';
import { useIBIS } from '../../../hooks/useIbis';
import Edit from '../../../components/button/edit';


const Header = ({ loginStore }) => {
  const IBIS = useIBIS(false);
  const isLogginedUser = loginStore && loginStore?.hasOwnProperty?.("size");
  const { pathname } = useLocation();
  const path = pathname.slice(1);

  const pathnameArray = removeMeta(path).split("/");
  const name = pathnameArray.slice(-1)[0]
    ? pathnameArray.slice(-1)[0]
    : pathnameArray.slice(-2)[0];
  const isCurrentPathDirectory =
    path.slice(-1) === "/" || path.slice(-1) === "";
  const isCurrentPathMeta = path !== removeMeta(path);
  const currentViewName = isCurrentPathMeta ? path.split("/").slice(-2)[0] : "";

  const [ directoryModal, setDirectoryModal ] = useState(false);
  const [ fileModal, setFileModal ] = useState(false);
  const [ editFileModal, setEditFileModal ] = useState(false);

  return (
    <Container fluid className="p-3">
      <Row className="m-0">
        <BreadCrumbs />
      </Row>
      <Row className="m-0">
        <Col sm="6">
          <Row>
            <h3 className="py-2 m-0">
              <b>{name}</b>
            </h3>
          </Row>
          <Row>
            <h3>
              <b>{currentViewName}</b>
            </h3>
          </Row>
        </Col>
        <Col sm="6">
          <span className="float-right d-flex">
            {isLogginedUser && ((isCurrentPathDirectory && !isCurrentPathMeta) || !IBIS.node) &&
              !IBIS.isLoading && (
              <AddNode
                createNewElFromURL={!IBIS.node}
                openFileModal={() => setFileModal(true)}
                openDirectoryModal={() => setDirectoryModal(true)}
              />
            )}
            {isLogginedUser && (!isCurrentPathDirectory && IBIS.node) && !IBIS.loading && <Download path={pathname} size={IBIS.node.size} />}
            {isLogginedUser && (!isCurrentPathDirectory && IBIS.node) && !IBIS.loading && <Edit setEditFileModal={setEditFileModal} 
              setFileModal={setFileModal} />}
            {isLogginedUser && (!isCurrentPathDirectory && IBIS.node) && !IBIS.loading && <Delete path={pathname} />}
            {(IBIS.node || !IBIS.isLoading) && <Refresh disabled={IBIS.isLoading}/>}
          </span>
        </Col>
      </Row>
      <Row className="m-0">
        <Attributes isHeader={true} />
      </Row>
      {IBIS.node?.items?.find(
        (node) => node.name.toLowerCase() === "readme.md"
      ) && (
        <Row>
          <ReadMe />
        </Row>
      )}
      <DirectoryCreator
        modal={directoryModal}
        closeModal={() => setDirectoryModal(false)}
      />
      <FileCreator
        setEditFileModal={setEditFileModal}
        editFileModal={editFileModal}
        modal={fileModal}
        closeModal={() => setFileModal(false)}
      />
    </Container>
  );
};

export default Header;

Header.propTypes = {
  pathname: PropTypes.string,
  loginStore: PropTypes.object,
};

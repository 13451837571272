import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import { getLogger } from '../utils/logger';

export const ErrorBoundary = (displayNameCallback, isModal = false) => (WrappedComponent) => {
  @withTranslation()
  class withErrorBoundaryComponent extends Component {
    state = {
      hasError: false,
      modal: true
    };

    closeModal = () => {
      this.setState({ modal: false });
    };

    componentDidCatch(error, errorInfo) {
      const logger = getLogger({ loggerName: 'errorBoundary' });
      this.setState({ hasError: true });
      // Log content of the error
      Sentry.withScope(scope => {
        // Log crashed component stack
        if (errorInfo.componentStack) {
          scope.setExtra('componentStack', errorInfo.componentStack);
        }
        logger.error(error);
      });
    }

    render() {
      const { t } = this.props;
      if (this.state.hasError) {
        if (isModal)
          return (
            <Modal isOpen={this.state.modal}>
              <ModalHeader>{t('common:error')}</ModalHeader>
              <ModalBody>
                {t('common:error_boundary.message')}: {displayNameCallback(this.props)}
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.closeModal}>OK</Button>
              </ModalFooter>
            </Modal>
          );
        else
          return (<h3 className="error-boundary-alert">
            {t('common:error_boundary.message')}: {displayNameCallback(this.props)}
          </h3>
          );
      }
      else
        return <WrappedComponent {...this.props} />;
    }
  }
  withErrorBoundaryComponent.propTypes = {
    t: PropTypes.func //HOC
  };

  return withErrorBoundaryComponent;
};
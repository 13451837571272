import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';

import {
  StyledNodesTileItem,
  StyledFileName,
  StyledIcon,
  StyledBody,
} from './nodesGrid.styled';
import { icons } from '../../../../../assets/svg/icons/index';
import Attributes from '../attributes/attributes';
import ImageIcon from './icon/imageIcon';

export const NodesListTile = ({
  attributes,
  name,
  type,
  content_type,
  onClick,
  innerRef,
  to,
}) => {
  const contentType =
    content_type && type !== "directory"
      ? content_type.split("/")[1].toUpperCase()
      : type === "directory"
        ? type
        : "-";

  const itemIcon = () => {
    if (type === "directory") {
      return icons["Directory"];
    }
    else if (content_type.split("/")[0] === "image") {
      return <ImageIcon name={name} />;
    }
    else if (icons[contentType] === undefined) {
      return icons["FILE"];
    }
    else {
      return icons[contentType];
    }
  };

  return (
    <StyledNodesTileItem onClick={onClick} to={to} tabIndex={0} ref={innerRef}>
      <StyledIcon>{itemIcon()}</StyledIcon>
      <StyledBody className="p-2">
        <Row className="m-0">
          <StyledFileName>{name}</StyledFileName>
        </Row>
        <Row className="m-0 my-auto">
          <Attributes attributes={attributes} isHeader={false} />
        </Row>
      </StyledBody>
    </StyledNodesTileItem>
  );
};

NodesListTile.propTypes = {
  attributes: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.number,
  user_content_last_modified: PropTypes.string,
  content_type: PropTypes.string,
  children: PropTypes.element,
  onClick: PropTypes.func,
  innerRef: PropTypes.func,
  to: PropTypes.string,
};

export default NodesListTile;

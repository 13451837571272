import { Input } from 'reactstrap';
import styled from 'styled-components';

import { theme } from '../../styles';

export const StyledInput = styled(Input)`
  height: 32px;
  border-radius: 6px;

  :focus {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px rgba(134, 143, 160, 0.32), 0px 0px 0px 4px ${theme.colors.primary[500]}66;
}
`;

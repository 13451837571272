import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Button } from '../../ibis-ui/components/button/button';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import * as _ from 'lodash';

import { checkIfLoginIsPossible, getProfileRedirect } from '../utils/authUtils';
import { logIn, logOut } from '../../oidc-middleware/actions/login';
import Loading from '../components/loading';
import { StyledDownIcon } from '../../ibis-ui/pages/topbar/topbar.styled';

@withTranslation()
@connect(
  state => ({
    loginStore: state.login.get('loginData'),
    loginFinishingInProgress: state.login.get('login_finishing_in_progress'),
  }),
  dispatch => {
    return bindActionCreators({
      logIn: logIn,
      logOut: logOut,
    }, dispatch);
  }
)
export default class Login extends Component {
  state = {
    dropdownOpen: false,
    profileRedirect: null,
  };
  _isMounted= false;

  componentDidMount() {
    this._isMounted = true;
    if (checkIfLoginIsPossible())
      getProfileRedirect().then(value => {
        if (this._isMounted)
          this.setState({ profileRedirect: value });
      });
  }

  componentWillUnmount(){
    this._isMounted = false;
  }

  dropdownToggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };

  renderLoginBar() {
    const { t } = this.props;
    const throttledLogin = _.throttle(this.props.logIn, 1000);

    if (!checkIfLoginIsPossible()) {
      //Login is impossible
      return null;
    }

    if (this.props.loginFinishingInProgress) {
      //login finishing is in progress
      return <Loading size="sm" />;
    }

    if (!this.props.loginStore) {
      //user is logged in
      return (
        <NavItem>
          <Button
            type="secondary"
            id='login'
            href="#"
            onClick={() => throttledLogin()}
          >
            {t('common:login')}
          </Button>
        </NavItem>
      );
    }

    //user in not logged in
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.dropdownToggle}>
        <DropdownToggle nav>
          {this.props.loginStore.get('username')} <StyledDownIcon />
        </DropdownToggle>
        <DropdownMenu right>
          {this.state.profileRedirect && <DropdownItem href={this.state.profileRedirect}>{t('common:account_settings')}</DropdownItem>}
          <DropdownItem href="#" onClick={this.props.logOut}>{t('common:logout')}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }

  render() {
    return (
      this.renderLoginBar()
    );
  }
}

Login.propTypes = {
  logIn: PropTypes.func, //HOC
  logOut: PropTypes.func, //HOC
  location: PropTypes.object,
  t: PropTypes.func, //HOC,
  loginStore: PropTypes.object, //HOC
  loginFinishingInProgress: PropTypes.bool, //HOC
};

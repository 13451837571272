import { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Map } from 'immutable';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useLocation } from 'react-router';
import IBIS from '../interface-actions/index';
import { tenantPath } from '../../main/utils/authUtils';

const initialState = Map({
  isLoading: false,
  error: null,
  node: null,
});

const selectJsState = createSelector(
  (state) => state.IBIS.nodes,
  (_, path) => path,
  (IBIS, path) => {
    return IBIS.get(path) ? IBIS.get(path).toJS() : initialState.toJS();
  }
);

export const useIBIS = (
  fetchOnInit = false,
  customPathname = "",
  fetchAll = false
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname: locationPathname } = useLocation();
  const oldPathname = locationPathname + customPathname;
  const pathname =
    (window.location.pathname.replace(tenantPath, "/") === locationPathname
      ? oldPathname
      : window.location.pathname.replace(tenantPath, "/") + oldPathname.slice(1));
  const isDirectory = pathname.endsWith("/");

  const state = useSelector((state) => selectJsState(state, pathname));

  const fetchData = useCallback(async () => {
    if (isDirectory)
      dispatch(
        IBIS.fetchDirectory({ path: pathname, replace: true, fetchAll })
      ).then((res) => {
        if (res?.headers && res?.headers["ibis-type"] === "symlink")
          history.push(res?.headers["ibis-target"] + window.location.search);
        if (res?.headers && res?.headers["ibis-type"] === "file")
          history.push(pathname.slice(0, -1) + window.location.search);
      });
    else
      dispatch(
        IBIS.fetchFile({ path: pathname, detectContentType: true })
      ).then((res) => {
        if (res?.headers && res?.headers["ibis-type"] === "symlink")
          history.push(res?.headers["ibis-target"] + window.location.search);
        if (res?.headers && res?.headers["ibis-type"] === "directory")
          history.push(pathname + "/" + window.location.search);
      });
  }, [ isDirectory, dispatch, pathname, fetchAll, history ]);

  const fetchMore = useCallback(async () => {
    dispatch(IBIS.fetchDirectory({ path: pathname, cursor: state.node?.more }));
  }, [ dispatch, pathname, state.node?.more ]);

  useEffect(() => {
    if (state.error?.response?.data?.code === "INVALID_NODE_TYPE")
      history.push(pathname.slice(0, -1) + window.location.search);
  }, [ state.error, history, pathname ]);

  useEffect(() => {
    if (fetchOnInit) {
      fetchData();
      return () => {
        dispatch(IBIS.clearNode({ path: pathname }));
      };
    };
  }, [ dispatch, fetchData, fetchOnInit, pathname ]);

  return {
    ...state,
    fetchData: fetchData,
    fetchMore: state.node?.more && fetchMore,
    delete: ({ path }) =>
      dispatch(IBIS._delete({ path: path ?? pathname })).then(() =>
        fetchData()
      ),
    createDirectory: ({ attributes, path }) =>
      IBIS.createDirectory({ path: path ?? pathname, attributes }).then(() =>
        fetchData()
      ),
    createFile: ({ file, path, onUploadProgress, signal }) =>
      IBIS.createFile({
        path: path ?? pathname,
        file,
        onUploadProgress,
        signal,
      }).then(() => fetchData()),
    patchFile: ({ file, path, onUploadProgress, signal }) =>
      IBIS.patchFile({
        path: path ?? pathname,
        file,
        onUploadProgress,
        signal,
      }).then(() => fetchData()),
  };
};

export default useIBIS;

import styled from 'styled-components';
import { Navbar } from 'reactstrap';

import { ReactComponent as Down } from '../../../assets/svg/icons/down.svg';
import { ReactComponent as Question } from '../../../assets/svg/icons/question.svg';
import { theme } from '../../styles';

export const topbarHeight = 56;

export const StyledNavbar = styled(Navbar)`
 height: ${topbarHeight}px;
 background-color: ${theme.colors.primary['700']};
 div.dropdown > a {
    color: #FFFFFF;
    font-weight: bold;
 }
`;

export const StyledQuestionIcon = styled(Question)`
  path {
    stroke: #FFFFFF;
  }
`;

export const StyledDownIcon = styled(Down)`
  path {
    stroke: #FFFFFF;
  }
`;
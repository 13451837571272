import styled from 'styled-components';

import { theme } from '../../styles';

export const StyledBadge = styled.span`
  background-color: ${theme.colors.grey["50"]};
  color: ${theme.colors.grey["600"]};
  border-radius: 4px;
  padding:1px 8px 1px 8px;
  font-size: ${theme.fontSize.small};
  text-transform:uppercase;
  svg{
    vertical-align: sub;
    margin-right:6px;
    transform: scale(0.75);
  }
`;
import { ReactComponent as Directory } from '../../../assets/svg/icons/directory.svg';
import { ReactComponent as JSON } from '../../../assets/svg/icons/json.svg';
import { ReactComponent as File } from '../../../assets/svg/icons/file.svg';
import { ReactComponent as PDF } from '../../../assets/svg/icons/pdf.svg';

export const icons = {
  Directory: <Directory/>,
  JSON: <JSON/>,
  FILE: <File/>,
  PDF: <PDF/>
};

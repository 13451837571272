import React from 'react';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

const loading = ({ className, size }) => (
  <div className={className}>
    <Spinner size={size} />
  </div>
);

loading.propTypes = {
  className: PropTypes.string, //HOC
  size: PropTypes.string, //HOC
};

export default loading;

import * as functions from '../../ibis-ui/services';
import actions from '../actions';

export const registerMapping = ({ name, path }) => (dispatch, getState) => {
  dispatch(actions.setMapping({ name: name, path: path }));
  dispatch(actions.updateCache({
    name: name,
    value: getState().IBIS.files.getIn([ path ])
  }));
};

export const patchJSON = ({ path, name, patch }) => (dispatch, getState) => {
  name = path ? getState().IBIS.mapping.getIn([ 'pathToName', path ]) : name;
  path = !path ? getState().IBIS.mapping.getIn([ 'nameToPath', name ]): path;
  dispatch(actions.applyPatch({ path: path, patch: patch }));
  dispatch(actions.updateCache({
    name: name,
    value: getState().IBIS.files.getIn([ path ])
  }));
  return functions.patchIBISfile({
    path, 
    content: getState().IBIS.files.getIn([ path ]).toJS()
  });
};

export const patchMappedJSON = ({ name, patch }) => {
  return patchJSON({ name, patch });
};

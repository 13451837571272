import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import { __env } from '../../../../../envloader';

export const NodeContainer = ({ name, type, children }) => {
  const { pathname } = useLocation();

  const isInFileProviderMode = () => {
    return Boolean(
      qs.parse(window.location.search, { ignoreQueryPrefix: true }).fileProvider
    );
  };

  const notifyHost = () => {
    window.top.postMessage(
      __env.IBIS_TENANT_URL.slice(0, -1) + pathname + name,
      "*"
    );
  };

  const redirect = () => {
    if (type !== "directory" && isInFileProviderMode()) {
      notifyHost();
    }
  };

  const newPath =
    (type === "directory" ? `${name}/` : name) + window.location.search;

  return React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      onClick: redirect,
      to: newPath,
      className: "m-5",
    });
  });
};

NodeContainer.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.element,
};

export default NodeContainer;

import React from 'react';
import { FaSyncAlt } from 'react-icons/fa';
import { Button } from './button';
import { useIBIS } from '../../hooks/useIbis';

const Refresh = (props) => {
  const IBIS = useIBIS();

  return (
    <Button type='secondary' className='ml-2' onClick={() => IBIS.fetchData()} {...props}><FaSyncAlt/></Button>
  );
};

export default Refresh;
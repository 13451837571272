import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import { uniqueId } from 'lodash';


import { StyledNodesListItem, StyledFileName, StyledTableCell } from './nodesList.styled';
import { ReactComponent as Clock } from '../../../../../assets/svg/icons/clock.svg';
import { ReactComponent as More } from '../../../../../assets/svg/icons/more.svg';
import { Button } from '../../../../components/button/button';
import { icons } from '../../../../../assets/svg/icons/index';
import { Badge } from '../../../../components/badge/badge';
import Delete from '../../../../components/button/delete';
import Dropdown from '../../../../components/dropdown/dropdown'; 

import Attributes from '../attributes/attributes';

export const NodesListItem = ({
  attributes,
  name,
  type,
  size,
  user_content_last_modified,
  content_type,
  children,
  onClick,
  to,
  innerRef,
}) => {
  const contentType =
    content_type && type !== "directory"
      ? content_type.split("/")[1].toUpperCase()
      : type === "directory"
        ? type
        : "-";

  const node = useRef();
  const [ dropdown, setDropdown ] = useState(false);
  const [ tooltipID, setTooltipID ] = useState(null);

  const fileSize = () => {
    if (size === 0) {
      return `0 B`;
    }
    const sizes = [ "B", "kB", "MB", "GB", "TB", "PB" ];
    const i = Math.floor(Math.log(size) / Math.log(1000));
    return parseFloat((size / Math.pow(1000, i)).toFixed(0)) + " " + sizes[i];
  };

  useEffect(() => {
    setTooltipID(uniqueId("content-type-"));
    return () => {
      setTooltipID(null);
    };
  }, []);

  const itemIcon = () => {
    if (type === "directory") {
      return icons["Directory"];
    } 
    else if (icons[contentType] === undefined) {
      return icons["FILE"];
    } 
    else {
      return icons[contentType];
    }
  };

  const dateFormat = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h12",
  };
  const lastModified = user_content_last_modified
    ? new Date(user_content_last_modified).toLocaleDateString(
      "pl-PL",
      dateFormat
    )
    : "-";

  return (
    <StyledNodesListItem
      onClick={onClick}
      to={to}
      tabIndex={0}
      role="link"
      ref={innerRef}
    >
      <StyledTableCell>
        {children}
        {itemIcon()}
      </StyledTableCell>
      <StyledTableCell>
        <StyledFileName>{name}</StyledFileName>
      </StyledTableCell>
      <StyledTableCell>
        <Badge id={tooltipID}>{contentType}</Badge>
        {tooltipID && (
          <UncontrolledTooltip
            placementPrefix="content-type-tooltip bs-tooltip"
            placement="bottom"
            target={tooltipID}
          >
            <span>{content_type || type}</span>
          </UncontrolledTooltip>
        )}
      </StyledTableCell>
      <StyledTableCell>
        <Badge>{size !== undefined ? fileSize() : "-"}</Badge>
      </StyledTableCell>
      <StyledTableCell>
        <div>
          <Clock />
          <span>{lastModified}</span>
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <Attributes attributes={attributes} isHeader={false} />
      </StyledTableCell>
      <StyledTableCell>
        <div className="pr-4">
          <div ref={node} className="position-relative">
            <Button
              type="menu"
              icon={<More />}
              onClick={(e) => {
                e.preventDefault();
                setDropdown(!dropdown);
              }}
            />
            {dropdown && (
              <Dropdown triggerRef={node} changeDropdownState={setDropdown}>
                <Delete type="destructive" name={name} />
              </Dropdown>
            )}
          </div>
        </div>
      </StyledTableCell>
    </StyledNodesListItem>
  );
};

NodesListItem.propTypes = {
  attributes: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.number,
  user_content_last_modified: PropTypes.string,
  content_type: PropTypes.string,
  children: PropTypes.element,
  onClick: PropTypes.func,
  innerRef: PropTypes.func,
  to: PropTypes.string,
};

import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { UncontrolledTooltip } from 'reactstrap';


import { useIBIS } from '../../../../hooks/useIbis';
import { StyledAttrbCount } from './attributes.styled';
import { Badge } from '../../../../components/badge/badge';

export const Attributes = ({ attributes, isHeader }) => {

  const IBIS = useIBIS();

  if ((IBIS.node === null || IBIS.node.attributes === undefined) && isHeader){
    return <></>;
  }

  const dataAttrib = isHeader ? IBIS.node.attributes : attributes;

  if (Object.keys(dataAttrib).length === 0) {
    if (isHeader) {
      return <></>;
    }
    return <Badge>-</Badge>;
  }

  const badges = Object.entries(dataAttrib).map((element, index) => {
    const attrId = uniqueId("attr-");
    return (
      <span key={attrId}>
        <Badge id={attrId} className={index === 0 ? "" : "ml-2"}>
          {element[1]}
        </Badge>
        <UncontrolledTooltip
          placementPrefix="content-type-tooltip bs-tooltip"
          placement="bottom"
          target={attrId}
        >
          <span>{element[0]} = {element[1]}</span>
        </UncontrolledTooltip>
      </span>
    );
  });

  if (Object.entries(dataAttrib).length > 2 && !isHeader) {
    const attrId = uniqueId("attr-");
    return (
      <>
        {badges.slice(0, 2)}
        <Badge id={attrId} className="ml-2">
          <StyledAttrbCount>{`+${badges.length - 2}`}</StyledAttrbCount>
          <UncontrolledTooltip
            placementPrefix="content-type-tooltip bs-tooltip"
            placement="bottom"
            target={attrId}
          >
            {Object.entries(dataAttrib).slice(2).map((badge) => {
              return <div className='w-100'>{badge[0]} = {badge[1]}</div>;
            })}
          </UncontrolledTooltip>
        </Badge>
      </>
    );
  }
  else {
    return <>{badges}</>;
  }
};

Attributes.propTypes = {
  attributes: PropTypes.object,
  isHeader: PropTypes.bool
};

export default Attributes;
import React from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

const notFound = ({ t }) => (
  <Alert color="warning">
    <FaSearch className="mr-3" />
    {t('common:not_found')}
  </Alert>
);

export default withTranslation()(notFound);

notFound.propTypes = {
  t: PropTypes.func, //HOC
};

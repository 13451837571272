import React, { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Container, Row, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components/button/button';
import {
  StyledModalBody,
  StyledFileInput,
  StyledModal,
} from './nodeCreator.styled';
import useIBIS from '../../hooks/useIbis';
import FileField from './fileField/fileField';
import UploadProgress from './fileField/uploadProgress';

const FileCreator = ({ modal, closeModal, setEditFileModal, editFileModal = false }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const IBIS = useIBIS();

  const splitedPathname = pathname.split('/');
  const singleFileName = splitedPathname[splitedPathname.length-1] 
    ? splitedPathname[splitedPathname.length-1] : splitedPathname[splitedPathname.length-2];
  const pathnameForSingleFileCreation = pathname.slice(0, pathname.indexOf(singleFileName)) || "";

  const [ files, setFiles ] = useState(editFileModal && IBIS?.node ? [ new File([ IBIS?.node?.content ], singleFileName) ] : []);
  const [ filesProgress, setFilesProgress ] = useState([]);
  const [ filesCancel, setFilesCancel ] = useState([]);

  useMemo(() => {
    if (editFileModal && IBIS.node && IBIS.node.content) {
      setFiles([ new File([ IBIS.node.content ], singleFileName) ]);
    }
  }, [ editFileModal, singleFileName, IBIS.node ]);

  const handleFile = (e) => {
    if (e.target.files) {
      if (!IBIS.node || editFileModal) setFiles([ ...e.target.files ]);
      else setFiles((files) => [ ...files, ...e.target.files ]);
    }
  };

  const setProgress = (index, progress) => {
    setFilesProgress((prev) =>
      prev.map((prev, i) => (i === index ? progress : prev))
    );
  };

  const submit = () => {
    if (files.length > 0) {
      setFilesProgress(new Array(files.length).fill(0));
      Promise.allSettled(
        files.map((file, index) => {
          const controller = new AbortController();
          const promise = editFileModal 
            ? IBIS.patchFile({
              path: pathnameForSingleFileCreation + singleFileName,
              file: file,
              signal: controller.signal,
              onUploadProgress: (progressEvent) =>
                setProgress(
                  index,
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                ),
            })
            : IBIS.createFile({
              path: !IBIS.node ? pathnameForSingleFileCreation + singleFileName : pathname + file.name,
              file: file,
              signal: controller.signal,
              onUploadProgress: (progressEvent) =>
                setProgress(
                  index,
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                ),
            });
          setFilesCancel((prev) => [ ...prev, controller ]);
          return promise;
        })
      ).then(() => {
        close();
      });
    }
  };

  const close = () => {
    setFiles([]);
    setFilesProgress([]);
    setFilesCancel([]);
    setEditFileModal(false);
    closeModal();
  };

  const cancel = () => {
    filesCancel.forEach((controller) => controller.abort());
    close();
  };

  const condForDisableBTN = files.length === 0 
    || files.some(el => (el.name === singleFileName && (IBIS?.node?.content && el.size === IBIS?.node?.content.size)));

  return (
    <StyledModal isOpen={modal} toggle={close}>
      <div className="p-3">
        <h6 className="m-0">{ editFileModal ? t("ibis-ui:edit_moadal_title") : t("ibis-ui:create_file")}</h6>
      </div>
      <StyledModalBody>
        <Container>
          <Row>
            <Col>
              <input
                id="actual-btn"
                hidden
                multiple
                type="file"
                name="file"
                onChange={handleFile}
              />
              <StyledFileInput className="float-right" htmlFor="actual-btn">
                + {editFileModal ? t("ibis-ui:upload_file") : t("ibis-ui:add_file")}
              </StyledFileInput>
            </Col>
          </Row>
          <Row>
            <Col className="p-1">
              <label>{t("ibis-ui:selected_files")}:</label>
            </Col>
          </Row>
          {files &&
            files.map((file, index) => {
              const fileName = !IBIS.node || editFileModal ? singleFileName : file.name;

              return filesProgress.length > 0 ? (
                <UploadProgress
                  progress={filesProgress[index]}
                  key={index}
                  name={fileName}
                  cancel={() => filesCancel[index].abort()}
                />
              ) : (
                <FileField
                  editFileModal={editFileModal}
                  createNewElFromURL={!IBIS.node}
                  fileName={fileName}
                  key={index}
                  file={file}
                  index={index}
                  setFiles={setFiles}
                />
              );
            }
            )}
        </Container>
      </StyledModalBody>
      <ModalFooter className="p-3">
        <Row className="m-0 float-right">
          <Button type="secondary" onClick={cancel} className={filesProgress.length === 0 ? "mr-4" : ""}>
            {t("ibis-ui:cancel")}
          </Button>
          {filesProgress.length === 0 && <Button disabled={condForDisableBTN} onClick={submit}>{t("ibis-ui:upload")}</Button>}
        </Row>
      </ModalFooter>
    </StyledModal>
  );
};

FileCreator.propTypes = {
  modal: PropTypes.bool,
  closeModal: PropTypes.func,
  setEditFileModal: PropTypes.func,
  editFileModal: PropTypes.bool,
};

export default FileCreator;

const forceUrlsWithTrailingSlashes = envs => {
  const appendSlash = (val) => {
    return val && 
    typeof val === 'string' && 
    val.length &&
    val.startsWith('http') && 
    val.slice(-1) !== "/" ?
      val + "/" : 
      val;
  };

  const ensureSlash = (property) => {
    if (envs.hasOwnProperty(property)) {
      let val = envs[property];
      if (val && Array.isArray(val) && val.length){
        val.forEach((env, index) => {
          envs[property][index] = appendSlash(env);
        });
      } 
      else {
        envs[property] = appendSlash(val);
      }
    }
  };

  // Add here every new env, which contain url, for forcing trailing slash
  const processedEnvs = [ 'AUTH_OPENID_PROVIDER', 'IBIS_TENANT_URL' ];

  for (let index = 0; index < processedEnvs.length; index++) {
    const property = processedEnvs[index];
    ensureSlash(property);
  }
};

export const __env = {};

forceUrlsWithTrailingSlashes(window.__env);
if (Array.isArray(window.__env.AUTH_OPENID_PROVIDER))
  window.__env.AUTH_OPENID_PROVIDER = window.__env.AUTH_OPENID_PROVIDER[0]; // there is no additional functionality for more than one IDP

Object.assign(__env, window.__env);

__env.NODE_ENV = process.env.NODE_ENV;
__env.PUBLIC_URL = process.env.PUBLIC_URL;


import { useCallback, useRef } from 'react';
import useIBIS from './useIbis';

export const useInfiniteScroll = () => {
  const IBIS = useIBIS();

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (IBIS.isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && IBIS.node.more) {
          IBIS.fetchMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [ IBIS ]
  );

  return {
    lastElementRef
  };
};

export default useIBIS;

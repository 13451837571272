import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton } from './button.styled';

export const Button = ({
  onClick,
  className,
  children,
  icon,
  svgProperty,
  type = "primary",
  ...rest
}) => {

  return (
    <StyledButton
      onClick={onClick}
      className={className}
      type={type}
      svgProperty={svgProperty || "stroke"}
      hasChildren={children && true}
      {...rest}
    >
      {icon}
      {children && <p className={`${icon ? 'ml-2' : ''} align-self-center m-0`}>{children}</p>}
    </StyledButton>
  );
};

Button.propTypes = {
  t: PropTypes.func, //HOC,
  onClick: PropTypes.func, //HOC,
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.element,
  type: PropTypes.string,
  svgProperty: PropTypes.string,
};
import React from 'react';
import PropTypes from 'prop-types';

import { StyledBadge } from './badge.styled';

export const Badge = ({ children, ...props }) => {
  return <StyledBadge {...props}>{children}</StyledBadge>;
};

Badge.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
};
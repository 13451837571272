import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import { StyledReameContainer } from './readMe.styled';
import { useIBIS } from '../../../../hooks/useIbis';
import { StyledText } from '../../file/file.styled';

const ReadMe = () => {
  const { t } = useTranslation();
  const IBIS = useIBIS(true, "README.md");

  const renderContent = () => {
    const dataType = IBIS.node.contentType;
    switch (dataType) {
    case "text/markdown":
      return <ReactMarkdown>{IBIS.node.content}</ReactMarkdown>;
    case "text/plain":
      return <StyledText>{IBIS.node.content}</StyledText>;

    default:
      return <h4>{t("ibis-ui:cannot_display")}</h4>;
    }
  };

  if (IBIS.isLoading) {
    return null;
  }

  if (!IBIS.node?.content) return null;

  return (
    <StyledReameContainer className="m-3 p-5 w-100">
      {renderContent()}
    </StyledReameContainer>
  );
};

export default ReadMe;
